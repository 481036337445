import * as React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import smoothscroll from 'smoothscroll-polyfill'
import { isBrowser } from '../../utils/utils'
import './navigation.css'

/**
 * Navigation Component. Create the left sidebar with the links to the other stories in the same category
 * @param {Object} props - Object with these properties:
 *  @param {Array} postList - Array with the metadata about the other links
 *  @param {String} color - Can be 'green' or 'blue'. Defines the color in hover and active state
 *  @param {String} actualSlug - slug of the blog post actually opened
 * @returns Navigation as JSX Component
 */
const Navigation = ({ postsList, color, actualSlug }) => {
  if (isBrowser()) smoothscroll.polyfill()

  /**
   * Create the JSX structure of the links
   * @returns array of JSX objects with the links
   */
  const getNavigationLinks = () => {
    const navigationLinks = []
    const prevPath = isBrowser() ? window.location.pathname : ''
    postsList.forEach((node, i) => {
      navigationLinks.push(
        <li key={'gl-navigation-link-' + i} className={'gl-navigation__element gl-navigation__element--' + color + ' ' + (node.fields.slug === actualSlug ? 'gl-navigation__element--active' : '')}>
          <Link className='gl-navigation__link' to={node.fields.slug} state={{ prevPath: prevPath }}>
            <p className='gl-navigation__number'>{i < 9 ? '0' + (i + 1) : (i + 1)}</p>
            <p className='gl-navigation__title'>{node.frontmatter.title}</p>
          </Link>
        </li>)
    })
    return navigationLinks
  }

  /**
   * Scroll up or down until the next link
   * @param {String} direction - 'up' or 'down'
   */
  const scrollTo = (direction) => {
    const wrapper = document.querySelector('.gl-navigation__list')
    const wrapperHeight = wrapper.getBoundingClientRect().height
    const scrollDistribution = [0]
    const scrollTop = wrapper.scrollTop
    const scrollBottom = scrollTop + wrapperHeight

    Array.from(wrapper.children).forEach((post, i) => {
      scrollDistribution.push(scrollDistribution[i] + post.getBoundingClientRect().height)
    })

    if (direction === 'up') {
      for (let i = 0; i < scrollDistribution.length; i++) {
        if (scrollDistribution[i] >= scrollTop) {
          wrapper.scrollTo({
            top: scrollDistribution[i > 0 ? i - 1 : 0],
            left: 0,
            behavior: 'smooth'
          })
          break
        }
      }
    } else {
      for (let j = scrollDistribution.length - 1; j >= 0; j--) {
        if (scrollDistribution[j] <= scrollBottom) {
          wrapper.scrollTo({
            top: scrollDistribution[j + 1 < scrollDistribution.length ? j + 1 : j] - wrapperHeight,
            left: 0,
            behavior: 'smooth'
          })
          break
        }
      }
    }
  }

  return (
    <nav className='gl-post__navigation'>
      <button className='gl-navigation__button-up' onClick={() => scrollTo('up')}>
        <img className='gl-navigation__button-icon' src='/arrow_up.svg' alt='Subir' />
      </button>
      <ul className='gl-navigation__list'>
        {getNavigationLinks()}
      </ul>
      <button className='gl-navigation__button-down' onClick={() => scrollTo('down')}>
        <img className='gl-navigation__button-icon' src='/arrow_down.svg' alt='Bajar' />
      </button>
    </nav>
  )
}

Navigation.defaultProps = {
  color: 'green'
}

Navigation.propTypes = {
  postsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  color: PropTypes.string,
  actualSlug: PropTypes.string.isRequired
}

export default Navigation
