export default class Comment {
  constructor (name, comment, date) {
    this.name = (typeof name === 'string' && name.length > 0) ? name : undefined
    this.comment = comment || ''

    if (typeof date === 'undefined' || date === null) {
      this.date = undefined
    } else if (typeof date === 'string') {
      this.date = new Date(date)
    } else if (date.toDate) {
      this.date = date.toDate()
    } else {
      this.date = date
    }
  }
}
