import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout/layout'
import Seo from '../../components/seo/seo'
import Article from '../article/article'
import Comments from '../comments/comments'
import Navigation from '../navigation/navigation'
import PostInformation from '../post-information/post-information'
import Subscribe from '../subscribe/subscribe'
import './blog-post.css'

/**
 * Blog Post Component. Create a page with a blog post
 * @param {Object} props - Object with these properties:
 *  @param {object} data - Data and metadata of the blog post
 *  @param {Object} location - Metadata about window.location
 * @returns Blog Post Template as JSX Component
 */
const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || 'Title'
  const image =post.frontmatter?.image || ''
  const slug = post.fields?.slug || ''
  const readingMinutes = Math.ceil(post.wordCount.words * 0.7 / 60)
  const [type, captionType, color] = post.frontmatter?.category === 'stories' ? ['stories', 'Cuentos e historias', 'blue'] : ['tech', 'Artículos DEV', 'green']
  const [subscribeVisibility, setSubscribeVisibility] = useState('closed')
  const [commentsVisibility, setCommentsVisibility] = useState('closed')

  const postsList = type === 'tech' ? data.tech?.nodes || [] : data.stories?.nodes || []
  /**
   * Toggle the value of subscribeVisibility, to show or hidden the subscribe window. The value can be 'open, 'closed', or 'fade-out'
   */
  const handleSubscribeButton = () => {
    if (subscribeVisibility === 'closed') {
      setSubscribeVisibility('open')
    } else {
      setSubscribeVisibility('fade-out')
      setTimeout(() => {
        setSubscribeVisibility('closed')
      }, 200)
    }
  }

  /**
   * Toggle the value of commentsVisible, to show or hidden the comments window. The value can be 'open, 'closed', or 'fade-out'
   */
  const handleCommentsButton = () => {
    if (commentsVisibility === 'closed') {
      setCommentsVisibility('open')
    } else {
      setCommentsVisibility('fade-out')
      setTimeout(() => {
        setCommentsVisibility('closed')
      }, 210)
    }
  }

  return (
    <>
      <Layout location={location} title={siteTitle}>
        <Seo
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          url={location?.href}
          image={image}
          lang="es"
        />
        <div className='gl-post'>
          <Navigation postsList={postsList} color={color} actualSlug={slug} />
          <Article title={post.frontmatter.title} content={post.html} color={color} />
          <PostInformation title={post.frontmatter.title} date={post.frontmatter.date} readingMinutes={readingMinutes} color={color} slug={slug} handleSubscribeButton={handleSubscribeButton} handleCommentsButton={handleCommentsButton} />
        </div>
      </Layout>
      <Subscribe type={type} captionType={captionType} visible={subscribeVisibility} changeVisibility={handleSubscribeButton} />
      <Comments type={type} visible={commentsVisibility} slug={slug} changeVisibility={handleCommentsButton} />
    </>
  )
}

export default BlogPostTemplate

/**
 * GraphQL for Blog Post
 */
export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        category
        image
      }
      fields {
        slug
      }
      wordCount {
        words
      }
    }
    tech: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {frontmatter: {date: {}, category: {eq: "tech"}}}) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
    },
    stories: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {frontmatter: {date: {}, category: {eq: "stories"}}}) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
    }
  }
`
