import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../../components/button/button'
import './post-information.css'
import FirebaseService from '../../services/firebase'
import LocalStorageService from '../../services/local-storage'
import { isBrowser } from '../../utils/utils'

/**
 * PostInformation Component. Create the right sidebar with information about the actual article
 * @param {Object} props - Object with these properties:
 *  @param {String} title - Article title
 *  @param {String} date - Writing date
 *  @param {Number} readingMinutes - Estimated time to read the article
 *  @param {String} color - Can be 'green' or 'blue'
 *  @param {String} slug - Post identifier slug
 *  @param {Function} handleSubscribeButton - Function to execute when the subscribe button is clicked
 *  @param {Function} handleCommentsButton - Function to execute when the comment button is clickec
 * @returns PostInformation as JSX Component
 */
const PostInformation = ({ title, date, readingMinutes, color, slug, handleSubscribeButton, handleCommentsButton }) => {
  /**
   * Instance of firebase service for connections to server
   */
  const firebaseService = FirebaseService()
  /**
   * Instance of localStorage service for connections to localStorage
   */
  const localStorageService = LocalStorageService()
  /**
   * State and setState for likeState.
   */
  const [likeState, setLikeState] = useState(false)

  const actualPath = isBrowser() ? window.location.href : '#'

  useEffect(() => {
    setLikeState(localStorageService.getLike(slug))
    // eslint-disable-next-line
  }, [])

  /*
   * Format date to show in spanish
   * @param {String} date - Date to format
   * @returns String with the date in Spanish in Format 'Enero 01, 2021'
   */
  const formatDate = (date) => {
    const months = {
      january: 'Enero',
      february: 'Febrero',
      march: 'Marzo',
      april: 'Abril',
      may: 'Mayo',
      june: 'Junio',
      july: 'Julio',
      august: 'Agosto',
      september: 'Septiembre',
      october: 'Octubre',
      november: 'Noviembre',
      december: 'Diciembre'
    }
    const splitDate = date.split(' ')
    return months[splitDate[0].toLowerCase()] + ' ' + splitDate[1] + ' ' + splitDate[2]
  }

  /**
   * Send the petition to firebase to like the post
   */
  const handleLikeButton = () => {
    if (likeState) {
      firebaseService.addLike(slug)
    } else {
      firebaseService.removeLike(slug)
    }
    setLikeState(localStorageService.toggleLike(slug))
  }

  return (
    <div className='gl-post__sidebar'>
      <p className='gl-post__date'>{formatDate(date)}<br />
        <span className='gl-post__read-time'>{readingMinutes} min de lectura</span>
      </p>
      <div className='gl-reaction'>
        <h2 className='gl-reaction__title'>¿TE GUSTÓ?</h2>
        <p className='gl-reaction__text'>Si quieres recibir más artículos como este a tu correo, ¡suscríbete!</p>
      </div>
      <div className='gl-subscribe'>
        <button className={`gl-subscribe__button-heart${likeState ? ' gl-subscribe__button-heart--active' : ''}`} onClick={handleLikeButton}> </button>
        <Button text='SUSCRIBIRME' color={color} fillMode='outline' size='small' handleClick={handleSubscribeButton} />
      </div>
      <div className='gl-clear' />
      <div className='gl-social'>
        <button className='gl-social__button-comments' onClick={handleCommentsButton}> </button>
        <a href={`https://www.linkedin.com/shareArticle?url=${actualPath}&title=${title}`} target='_blank' rel='noreferrer' className='gl-social__button-linkedin' title='Compartir en LinkedIn'> </a>
        <a href={`https://twitter.com/share?url=${actualPath}&text=${title}&via=GersonLazaroC`} target='_blank' rel='noreferrer' className='gl-social__button-twitter' title='Compartir en Twitter'> </a>
        <a href={`https://www.facebook.com/sharer.php?u=${actualPath}`} target='_blank' rel='noreferrer' className='gl-social__button-facebook' title='Compartir en Facebook'> </a>
        <div className='gl-clear' />
      </div>
      <div className='gl-clear' />
    </div>
  )
}

PostInformation.defaultProps = {
  color: 'green'
}

PostInformation.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  readingMinutes: PropTypes.number.isRequired,
  handleSubscribeButton: PropTypes.func.isRequired,
  handleCommentsButton: PropTypes.func.isRequired
}

export default PostInformation
