/**
 * Service to manage the connection to local storage
 * @returns an object exposing the public methods
 */
const LocalStorageService = () => {
  /**
   * Toggle the like for a given slug
   * @param {String} slug - Post identifier
   * @returns the new value for the given slug
   */
  const toggleLike = (slug) => {
    if (window.localStorage.getItem(`like-for-${slug}`) === 'false') {
      window.localStorage.setItem(`like-for-${slug}`, 'true')
    } else {
      window.localStorage.setItem(`like-for-${slug}`, 'false')
    }
    return getLike(slug)
  }

  /**
   * Get the value for like in a given slug
   * @param {String} slug - Post identifier
   * @returns value for like in the given slug
   */
  const getLike = (slug) => {
    return window.localStorage.getItem(`like-for-${slug}`) === 'true'
  }

  return {
    toggleLike,
    getLike
  }
}

export default LocalStorageService
