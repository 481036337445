import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '../../components/button/button'
import './subscribe.css'

/**
 * Subscribe Component. Create the component to subscribe to content
 * @param {Object} props - Object with these properties:
 *  @param {String} type - Can be 'stories' or 'tech'
 *  @param {String} captionType - Text to show in subscribe to articlkes of this type
 *  @param {Boolean} visible - Subscribe visibility. Posible values are 'open', 'fade-out' and 'closed'
 *  @param {Function} changeVisibility - Function to execute to change the visibility of the window
 * @returns Subscribe as JSX Component
 */
const Subscribe = ({ type, captionType, visible, changeVisibility }) => {
  return (
    <div className={`gl-subscribe-modal gl-subscribe-modal--${visible === 'open' ? 'visible' : (visible === 'closed' ? 'hidden' : 'fade-out')} gl-subscribe-modal--${type === 'stories' ? 'blue' : 'green'}`}>
      <div className='gl-subscribe-modal__overlay'>
        <div className='gl-subscribe-modal__window'>
          <button className='gl-subscribe-modal__close-button' onClick={changeVisibility}>
            <img className='gl-subscribe-modal__close-img' src='/close.svg' alt='Cerrar la ventana' />
          </button>
          <h1 className='gl-subscribe-modal__title'>ENVIARÉ MIS PRÓXIMAS PUBLICACIONES A TU CORREO</h1>
          <form className='gl-subscribe-modal__form' action='https://gersonlazaro.us6.list-manage.com/subscribe/post?u=ad46f7a5567e146f5ff2bbaec&amp;id=30e5ae98eb' method='post' target='_blank'>
            <label className='gl-subscribe-modal__radio-caption'>{captionType}
              <input type='radio' name='TIPO' className='gl-subscribe-modal__type-input' value={captionType} />
              <div className='gl-subscribe-modal__radio' />
            </label>
            <label className='gl-subscribe-modal__radio-caption'>Todo tipo de artículos
              <input type='radio' name='TIPO' className='gl-subscribe-modal__type-input' value='Todo tipo de artículos' />
              <div className='gl-subscribe-modal__radio' />
            </label>
            <label className='gl-subscribe-modal__label-email' htmlFor='gl-subscribe-email'>Email:</label>
            <div className='gl-subscribe-modal__email-form'>
              <input type='email' className='gl-subscribe-modal__email' id='gl-subscribe-email' name='EMAIL' placeholder='Escribe aquí tu E-mail' />
              <Button text='SUSCRIBIRME' color='gradient' fillMode='fill' size='small' handleClick={() => {}} />
              <input type='hidden' name='b_ad46f7a5567e146f5ff2bbaec_30e5ae98eb' />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

Subscribe.defaultProps = {
  type: 'tech',
  captionType: 'Artículos DEV',
  visible: 'closed'
}

Subscribe.propTypes = {
  type: PropTypes.string,
  captionType: PropTypes.string,
  visible: PropTypes.string,
  changeVisibility: PropTypes.func.isRequired
}

export default Subscribe
