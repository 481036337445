import * as React from 'react'
import './article.css'

/**
 * Article Component. Create a component with an blog article
 * @param {Object} props - Object with these properties:
 *  @param {String} title - Article title
 *  @param {String} content - Article content
 *  @param {String} color - Article color. Can be 'green' for tech articles or 'blue' for stories
 * @returns Article as JSX Component
 */
const Article = ({ title, content, color }) => {
  return (
    <article
      className='gl-article'
      itemScope
      itemType='http://schema.org/Article'
    >
      <header className='gl-article__header'>
        <h1 itemProp='headline' className='gl-article__title'>{title}</h1>
      </header>
      <section
        dangerouslySetInnerHTML={{ __html: content }}
        itemProp='articleBody'
        className={`gl-article__content gl-article__content--${color}`}
      />
    </article>
  )
}

export default Article
