import * as React from 'react'
import './loader.css'

/**
 * Loader Component. Create a loading animation
 * @param {Object} props - Object with these properties:
 *  @param {Boolean} state - Comments State. Can be 'open' if the loader should be visible, 'fade-out' to start the animation end, and 'closed' to end the animation
 * @returns Loader as JSX Component
 */
const Loader = ({ state }) => {
  return (
    <div className={`gl-loader ${state === 'open' ? 'gl-loader--visible' : (state === 'fade-out' ? 'gl-loader--fade-out' : 'gl-loader--hidden')}`}>
      <img src='/gersonlazaro-logo.svg' alt='loading' className='gl-loader__image gl-loader__image--complete' />
      <img src='/gersonlazaro-logo-loading.svg' alt='loading' className='gl-loader__image' />
    </div>
  )
}

export default Loader
