import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import FirebaseService from '../../services/firebase'
import Button from '../../components/button/button'
import Loader from '../../components/loader/loader'
import Comment from '../../model/comment'
import './comments.css'

/**
 * Comments Component. Create a window to write and read comments
 * @param {Object} props - Object with these properties:
 *  @param {Boolean} visible - Comments Visibility. Posible values are 'open', 'fade-out' and 'closed'
 *  @param {String} type - Type can be 'stories' or 'tech'. This parameter change some colors in the UI
 *  @param {String} slug - Representative slug for blog post with comments
 *  @param {Function} changeVisibility - method to execute when the component visibility changes
 * @returns Comments as JSX Component
 */
const Comments = ({ visible, type, slug, changeVisibility }) => {
  /**
   * State and setState for writing mode. This is true when the user is writing a comment, false in another case
   */
  const [writingMode, setWritingMode] = useState(false)
  /**
   * State and setState for comments Components. This is an array with JSX components
   */
  const [comments, setComments] = useState([])
  /**
   * State and setState for new comments. This is a string attached to input for new comment
   */
  const [newComment, setNewComment] = useState('')
  /**
   * State and setState for valid comments. This is a boolean indicating if the comment is valid (The comment if valid if their lenght > 0)
   */
  const [validComment, setValidComment] = useState(true)
  /**
   * State and setState for name. This is a string attached to input for name in "new comment" section.
   */
  const [name, setName] = useState('')
  /**
   * State and setState for loading new comments. This is a boolean indicating if the new comment is loading next to be sended
   */
  const [loadingNewComment, setLoadingNewComment] = useState('closed')
  /**
   * State and set State for loading comments. This is a boolean indicating if the comments are loaded from firebase
   */
  const [loadingComments, setLoadingComments] = useState('open')
  /**
   * Instance of firebase service for connections to server
   */
  const firebaseService = FirebaseService()

  /**
   * Const arrays for random names and images when the user name is empty
   */
  const pics = ['Foca', 'Conejo', 'Mariposa']
  const descriptions = ['friki', 'sabelotodo', 'nerd', 'intelectual']

  /**
   * useEffect as componentDidMount. Call the method to get the comment list on mount
   */
  useEffect(() => {
    getCommentsList()
    // eslint-disable-next-line
  }, [])

  /**
   * Enable the writing mode and set the focus in the name input
   */
  const enterWritingMode = () => {
    if (!writingMode) {
      setWritingMode(true)
      setTimeout(() => {
        document.querySelector('.gl-comments-sidebar__input').focus()
      }, 0)
    }
  }

  /**
   * Validate if the user clicks outside the form, and exit the writing mode
   * @param {Event} event - User's click
   */
  const exitWritingMode = (event) => {
    const validClasses = ['gl-comments-sidebar__textarea', 'gl-comments-sidebar__input', 'gl-comments-sidebar__form--writing', 'gl-comments-sidebar__button', 'gl-button']
    let containsValidClass = false
    event.target?.className?.split(' ').forEach((className) => {
      if (validClasses.includes(className)) {
        containsValidClass = true
      }
    })
    if (!containsValidClass) {
      setValidComment(true)
      setWritingMode(false)
    }
  }

  /**
   * Format the date in a format of type "transcurred time since"
   * @param {String} time - Date of the comment
   * @returns a string with the date formatted in format "transcurred time since"
   */
  const formatTime = (time) => {
    const now = new Date()
    const originalDate = new Date(time)
    const elapsedMinutes = Math.trunc((now - originalDate) / 60000)
    if (elapsedMinutes < 60) return `${elapsedMinutes} minuto${elapsedMinutes === 1 ? '' : 's'}`
    const elapsedHours = Math.trunc(elapsedMinutes / 60)
    if (elapsedHours < 24) return `${elapsedHours} hora${elapsedHours === 1 ? '' : 's'}`
    const elapsedDays = Math.trunc(elapsedHours / 24)
    if (elapsedDays < 30) return `${elapsedDays} día${elapsedDays === 1 ? '' : 's'}`
    const elapsedMonths = Math.trunc(elapsedDays / 30)
    if (elapsedMonths < 12) return `${elapsedMonths} mes${elapsedMonths === 1 ? '' : 'es'}`
    const elapsedYears = Math.trunc(elapsedMonths / 12)
    return `${elapsedYears} año${elapsedYears === 1 ? '' : 's'}`
  }

  /**
   * Get the list of comments and set the comment state
   */
  const getCommentsList = async () => {
    const list = await firebaseService.getComments(slug)
    const commentsList = []

    list.forEach((comment, i) => {
      commentsList.push(createCommentStructure(comment, i))
    })
    setComments(commentsList)
    setLoadingComments('fade-out')
    setTimeout(() => {
      setLoadingComments('closed')
    }, 200)
  }

  /**
   * Create the JSX structure for a simple comment
   * @param {Comment} comment - Object of type comment
   * @param {Number} key - Unique key for structure
   * @returns JSX Comment
   */
  const createCommentStructure = (comment, key) => {
    const actualPic = key % 3
    const actualDescription = key % 4
    return (
      <li className='gl-comments-sidebar__comment' key={`gl-comments-sidebar__comment-${key}`}>
        <div className='gl-comments-sidebar__autor'>
          <img src={`/${pics[actualPic]}.svg`} alt={comment.name} className='gl-comments-sidebar__pic' />
          <div className='gl-comments-sidebar__autor-info'>
            <h2 className='gl-comments-sidebar__name'>{(comment.name ? comment.name : `${pics[actualPic]} ${descriptions[actualDescription]}`)}</h2>
            <p className='gl-comments-sidebar__date'>{formatTime(comment.date)}</p>
          </div>
        </div>
        <p className='gl-comments-sidebar__comment-text'>{comment.comment}</p>
      </li>
    )
  }

  /**
   * Send a comment to server
   */
  const sendComment = async () => {
    if (newComment === '') {
      setValidComment(false)
    } else {
      try {
        setLoadingNewComment('open')
        const comment = new Comment(name, newComment, new Date().toString())
        await firebaseService.addComment(slug, comment)
        const key = comments.length
        const newCommentComponent = createCommentStructure(comment, key)
        setComments([newCommentComponent, ...comments])
        setNewComment('')
        setName('')
      } catch (e) {
        throw Error(e)
      } finally {
        setLoadingNewComment('fade-out')
        setTimeout(() => {
          setLoadingNewComment('closed')
        }, 200)
      }
    }
  }

  /**
   * Fired when the user write in the comment textarea
   * @param {Event} event - User event
   */
  const setNewCommentAndClearValidations = (event) => {
    if (newComment !== '' && !validComment) {
      setValidComment(true)
    }
    setNewComment(event.target.value)
  }

  /**
   * Disable the default action for forms
   * @param {Event} event - User event
   */
  const disableSubmit = (event) => {
    event.preventDefault()
  }

  return (
    <div className={`gl-comments-sidebar gl-comments-sidebar--${visible === 'open' ? 'visible' : (visible === 'closed' ? 'hidden' : 'fade-out')} gl-comments-sidebar--${type === 'stories' ? 'blue' : 'green'}`}>
      <div className='gl-comments-sidebar__overlay' role='button' tabIndex='0' onClick={exitWritingMode} onKeyDown={exitWritingMode}>
        <div className='gl-comments-sidebar__window'>
          <h1 className='gl-comments-sidebar__title'>COMENTARIOS</h1>
          <button className='gl-comments-sidebar__close-button' onClick={changeVisibility}>
            <img className='gl-comments-sidebar__close-img' src='/close.svg' alt='Cerrar la ventana' />
          </button>
          <form onSubmit={disableSubmit} className={`gl-comments-sidebar__form${writingMode ? ' gl-comments-sidebar__form--writing' : ''}${!validComment ? ' gl-comments-sidebar__form--invalid gl-comments-sidebar__form--' + (type === 'stories' ? 'blue' : 'green') : ''}`}>
            <input type='text' className='gl-comments-sidebar__input' placeholder='Nombre' value={name} onChange={(event) => { setName(event.target.value) }} />
            <textarea name='gl-comments-sidebar-textarea' className='gl-comments-sidebar__textarea' placeholder={`${writingMode ? 'Mensaje' : 'Escribe aquí tu mensaje...'}`} onFocus={enterWritingMode} value={newComment} onChange={setNewCommentAndClearValidations} />
            <div className='gl-comments-sidebar__button'>
              <Button text='ENVIAR' color={type === 'stories' ? 'blue' : 'green'} fillMode='outline' size='small' handleClick={sendComment} />
            </div>
            <Loader state={loadingNewComment} />
          </form>
          <ul className='gl-comments-sidebar__comments_list'>
            {comments}
            <Loader state={loadingComments} />
          </ul>
        </div>
      </div>
    </div>
  )
}

Comments.defaultProps = {
  visible: 'closed',
  type: 'stories'
}

Comments.propTypes = {
  visible: PropTypes.string,
  type: PropTypes.string,
  slug: PropTypes.string.isRequired,
  changeVisibility: PropTypes.func.isRequired
}

export default Comments
