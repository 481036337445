import firebase from 'firebase/app'
import 'firebase/firestore'

import Comment from '../model/comment'
import { firebaseConfig } from '../constants/constant'

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
/**
 * Instance of firestore
 */
const db = firebase.firestore()

/**
 * Service to manage the connection to firebase
 * @returns an object exposing the public methods
 */
const FirebaseService = () => {
  /**
   * Add a like to the post
   * @param {String} slug - Slug of the post to like
   */
  const addLike = (slug) => {
    const increment = firebase.firestore.FieldValue.increment(1)
    db.collection('posts')
      .doc(slug).set({ likes: increment }, { merge: true })
  }

  /**
   * Remove a like to the post
   * @param {String} slug - Slug of the post to remove like
   */
  const removeLike = (slug) => {
    const decrement = firebase.firestore.FieldValue.increment(-1)
    db.collection('posts')
      .doc(slug).set({ likes: decrement }, { merge: true })
  }

  /**
   * Get the list of comments saved for a slug
   * @param {String} slug - Slug of the post to get comments
   * @returns list of comments
   * @async the method is async because it should get the comments from firebase
   */
  const getComments = async (slug) => {
    const comments = []
    try {
      const firebaseDocs = await db.collection(`/posts${slug}comments`).orderBy('date', 'desc').get()
      firebaseDocs.docs.forEach((doc) => {
        const comment = doc.data()
        comments.push(new Comment(comment.user, comment.comment, comment.date))
      })
    } catch (error) {
      console.log(error)
    }
    return comments
  }

  /**
   * Add a new comment to slug
   * @param {String} slug - Slig of the post to comment
   * @param {Comment} comment - Object of type comment
   * @returns a promise to fulfill when the comment is correctly saved
   */
  const addComment = async (slug, comment) => {
    try {
      return await db.collection(`/posts${slug}comments`)
        .doc()
        .set({
          user: comment.name || '',
          comment: comment.comment,
          date: firebase.firestore.FieldValue.serverTimestamp()
        }, {
          merge: true
        })
    } catch (error) {
      throw Error(error)
    }
  }

  return {
    addLike,
    removeLike,
    getComments,
    addComment
  }
}

export default FirebaseService
